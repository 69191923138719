import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../Logo.png';
import { useNavigate } from 'react-router-dom';
import { signInFailure, signInStart, signInSuccess } from '../../redux/user/userSlice';
import { setAuthToken } from '../../utils/jwtUtils';
import { setAlert } from '../../redux/alert/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openPopup } from '../../redux/popup/popupSlice';
import Spinner from '../../components/Spinner';

const defaultTheme = createTheme();

function AuthLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  // Initialize formData with email from localStorage, if present
  const [formData, setFormData] = useState({
    email: localStorage.getItem('rememberEmail') || '',
    password: '',
    remember: localStorage.getItem('rememberEmail') ? true : false,
  });

  // Function to handle onChange for text fields
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.remember) {
      localStorage.setItem('rememberEmail', formData.email);
    } else {
      localStorage.removeItem('rememberEmail');
    }

    try {
      dispatch(signInStart());
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email, password: formData.password }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(
          openPopup({
            title: 'Do you want to log in your account?',
            message: data.message || 'Something went wrong, Please try with correct email and password!',
          })
        );
        dispatch(signInFailure(data.message || 'Login failed'));
        return;
      }

      dispatch(signInSuccess(data));
      setAuthToken(data?.accessToken);
      dispatch(setAlert({ type: 'success', message: 'User logged in successfully!' }));
      navigate('/');
    } catch (err) {
      dispatch(
        setAlert({ type: 'error', message: 'Something went wrong, Please try with correct email and password again!' })
      );
      dispatch(signInFailure(err.message));
    }
  };

  useEffect(() => {
    // If email is saved in localStorage, set it as default value for the email field
    const savedEmail = localStorage.getItem('rememberEmail');
    if (savedEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: savedEmail,
        remember: true,
      }));
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          {loading && <Spinner />}
          <Box
            component='img'
            sx={{
              height: 64,
            }}
            alt='Logo'
            src={Logo}
          />
          <Typography variant='h1' sx={{ mt: 1, mb: 4, fontWeight: 'bold', fontSize: '1.1rem' }}>
            공기질 통합 모니터링 플랫폼
          </Typography>

          <Typography level='body-sm'>세션 시작을 위해 로그인 해주세요</Typography>
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='이메일 주소'
              name='email'
              value={formData.email}
              onChange={handleChange}
              autoFocus
            />

            <Link href='/forgotPassword' variant='body2'>
              비밀번호를 잊으셨나요?
            </Link>

            <TextField
              sx={{ mt: 0.5 }}
              margin='normal'
              required
              fullWidth
              name='password'
              label='비밀번호'
              type='password'
              id='password'
              value={formData.password}
              onChange={handleChange}
            />
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item>
                <Link href='/register' variant='body2'>
                  계정이 없나요? 가입하기
                </Link>
              </Grid>
              <FormControlLabel
                control={
                  <Checkbox name='remember' color='primary' checked={formData.remember} onChange={handleChange} />
                }
                label='이메일 기억하기'
              />
            </Grid>
            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2, borderRadius: '20px' }}>
              로그인
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AuthLogin;
