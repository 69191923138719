

export const columns = [
  {
    accessorKey: 'number',
    header: 'number',
    size: 200,
  },
  {
    accessorKey: 'title',
    header: 'title',
    size: 1000,
  },
  {
    accessorKey: 'views',
    header: 'views',
    size: 300,
  },
  {
    accessorKey: 'date',
    header: 'last_modified_date',
    size: 300,
  },
  {
    accessorKey: 'registrant',
    header: 'author',
    size: 300,
  },

];