export const floors = [1, 2, 3, 4];
export const sensors = [
  {
    sensorName: 'AQI',
  },
  {
    sensorName: 'Bacteria',
  },
  {
    sensorName: 'PM10',
  },
  {
    sensorName: 'TVOC',
  },
  {
    sensorName: 'HCHO',
  },
  {
    sensorName: 'CO',
  },
  {
    sensorName: 'CO2',
  },
  {
    sensorName: 'CH2O',
  },
  {
    sensorName: 'NO2',
  },
];
export const MapCenter = [37.568171, 126.646160]
export const MapMarkers = [
  { position: [37.568171, 126.646160], popupText: "Marker in Center" }
];

function createData(id, device, status, level) {
  return { id, device, status: { status, level } };
}
export const floorDevicesData = [
  createData('01', 'Gate A-A', 0, 90),
  createData('02', 'Hall A’', 1, 100),
  createData('03', 'Gate B-A', 2, 70),
  createData('04', 'Hall C’', 1, 50),
  createData('05', 'Room Jupiter', 1, 50),
  createData('06', 'Center', 0, 80),
  createData('07', 'Gate A-A', 0, 90),
  createData('08', 'Hall A’', 1, 100),
  createData('09', 'Gate B-A', 2, 70),
  createData('10', 'Hall C’', 1, 50),
];

export const totalDevicesData = [
  createData('01', '1F - Gate A-A', 0, 90),
  createData('02', '3F - Hall A’', 1, 100),
  createData('03', '2F - Gate B-A', 2, 70),
  createData('04', '4F - Hall C’', 1, 50),
  createData('05', '3F - Room Jupiter', 1, 50),
  createData('06', '1F - Center', 0, 80),
  createData('07', '4F - Gate A-A', 0, 90),
  createData('08', '3F - Hall A’', 1, 100),
  createData('09', '4F - Gate B-A', 2, 70),
  createData('10', '1F - Hall C’', 1, 50),
  createData('11', '3F - Room Jupiter', 1, 50),
  createData('12', '2F - Center', 0, 80),
];