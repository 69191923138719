import React, { useState } from 'react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tiptap } from '../../components/tiptap/TextEditer';
import {
  postAnswerFailure,
  postAnswerStart,
  postAnswerSuccess,
} from '../../redux/answer/answerSlice';
import { getAuthToken } from '../../utils/jwtUtils';
import { openPopup } from '../../redux/popup/popupSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ReReplyPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reply, setReply] = useState({ answer: '' });
  const [isContentValid, setIsContentValid] = useState(false);
  const { currentQuestion } = useSelector((state) => state.question);
  const { currentAnswer } = useSelector((state) => state.answer);
  const { currentUser } = useSelector((state) => state.user);
  const answerText = typeof currentAnswer?.answer === 'string' ? currentAnswer.answer : '';
  const dbAnswer = parse(answerText);
  const token = getAuthToken();

  const handleChange = (value) => {
    setReply({ ...reply, answer: value });

    const isValidContent = value.trim() !== '' && value.trim() !== '<p></p>';

    setIsContentValid(isValidContent);
  };


  const handleReReply = async (e) => {
    e.preventDefault();
    if (!isContentValid) return; // Additional guard
    try {
      dispatch(postAnswerStart());
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/answers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...reply, questionId: currentQuestion?.id, userId: currentUser?.id }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(
          openPopup({
            title: 'Are you trying to reply?',
            message: data.message || 'Something went wrong, Could not post your reply!',
          })
        );
        dispatch(postAnswerFailure(data.message));
        return;
      }

      dispatch(setAlert({ type: 'success', message: 'Replied successfully!' }));
      dispatch(postAnswerSuccess(data));
      navigate('/questionList');
    } catch (err) {
      dispatch(postAnswerFailure(err.message));
    } finally {
      setReply({ answer: '' });
      setIsContentValid(false);
    }
  };

  return (
    <Box sx={{ width: { xs: '100%', md: '90%' }, mt: 4, mx: 'auto', p: 4, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 3 }}>
      <Typography variant="h6" gutterBottom>
        {t('question_view')}
      </Typography>
      <Box sx={{ mb: 2, p: 2, bgcolor: 'rgba(248, 249, 250, 1)', borderRadius: 1 }}>
        <Typography variant="body1">{dbAnswer}</Typography>
      </Box>
      <form onSubmit={handleReReply}>
        <Tiptap
          setDescription={handleChange}
          value={reply.answer}  // Ensure Tiptap is initialized with the current value
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
            disabled={!isContentValid} // Disable button if content is invalid
          >
            {t('reply')}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default ReReplyPage;
