import moment from 'moment';

export const overviewData = [
  {
    title: 'avg_bacteria_level',
    count: '1,212',
  },
  {
    title: 'max_bacteria_level',
    count: '45,845',
  },
  {
    title: 'min_bacteria_level',
    count: '44,633',
  },
];

export const sensorElements = [
  {
    sensor: 'TAB',
  },
  {
    sensor: 'PM10',
  },
  {
    sensor: 'PM25',
  },
  {
    sensor: 'CO',
  },
  {
    sensor: 'CO2',
  },
  {
    sensor: 'TVOC',
  },
  {
    sensor: 'CH2O',
  },
  {
    sensor: 'NO2',
  },
];


export const fontStyle = {
  fontWeight: 600,
  fontSize: '1rem',
  lineHeight: 1.5,
  fontFamily: '"Public Sans", sans-serif',
};


export const months = [
  {
    name: 'Total',
    startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYYMMDDHHmmss'), // January 1st of the current year, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // December 31st, 23:59:59
  },
  {
    name: 'January',
    startDate: moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYYMMDDHHmmss'), // January 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 0, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // January 31st, 23:59:59
  },
  {
    name: 'February',
    startDate: moment(new Date(new Date().getFullYear(), 1, 1)).format('YYYYMMDDHHmmss'), // February 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 1, 28, 23, 59, 59)).format('YYYYMMDDHHmmss') // February 28th, 23:59:59 (non-leap year)
  },
  {
    name: 'March',
    startDate: moment(new Date(new Date().getFullYear(), 2, 1)).format('YYYYMMDDHHmmss'), // March 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 2, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // March 31st, 23:59:59
  },
  {
    name: 'April',
    startDate: moment(new Date(new Date().getFullYear(), 3, 1)).format('YYYYMMDDHHmmss'), // April 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 3, 30, 23, 59, 59)).format('YYYYMMDDHHmmss') // April 30th, 23:59:59
  },
  {
    name: 'May',
    startDate: moment(new Date(new Date().getFullYear(), 4, 1)).format('YYYYMMDDHHmmss'), // May 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 4, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // May 31st, 23:59:59
  },
  {
    name: 'June',
    startDate: moment(new Date(new Date().getFullYear(), 5, 1)).format('YYYYMMDDHHmmss'), // June 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 5, 30, 23, 59, 59)).format('YYYYMMDDHHmmss') // June 30th, 23:59:59
  },
  {
    name: 'July',
    startDate: moment(new Date(new Date().getFullYear(), 6, 1)).format('YYYYMMDDHHmmss'), // July 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 6, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // July 31st, 23:59:59
  },
  {
    name: 'August',
    startDate: moment(new Date(new Date().getFullYear(), 7, 1)).format('YYYYMMDDHHmmss'), // August 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 7, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // August 31st, 23:59:59
  },
  {
    name: 'September',
    startDate: moment(new Date(new Date().getFullYear(), 8, 1)).format('YYYYMMDDHHmmss'), // September 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 8, 30, 23, 59, 59)).format('YYYYMMDDHHmmss') // September 30th, 23:59:59
  },
  {
    name: 'October',
    startDate: moment(new Date(new Date().getFullYear(), 9, 1)).format('YYYYMMDDHHmmss'), // October 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 9, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // October 31st, 23:59:59
  },
  {
    name: 'November',
    startDate: moment(new Date(new Date().getFullYear(), 10, 1)).format('YYYYMMDDHHmmss'), // November 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 10, 30, 23, 59, 59)).format('YYYYMMDDHHmmss') // November 30th, 23:59:59
  },
  {
    name: 'December',
    startDate: moment(new Date(new Date().getFullYear(), 11, 1)).format('YYYYMMDDHHmmss'), // December 1st, 00:00:00
    endDate: moment(new Date(new Date().getFullYear(), 11, 31, 23, 59, 59)).format('YYYYMMDDHHmmss') // December 31st, 23:59:59
  }
];



