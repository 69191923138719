import React from 'react';
import { IoEyeSharp } from "react-icons/io5";
import { Box, Typography } from '@mui/material';
import DisplayColorFulTable from './DisplayColorFulTable';
import { useTranslation } from 'react-i18next';

function DisplayBacteriaData({ currentReportInfo }) {
  const { t } = useTranslation()

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: { xs: 2, md: 8, lg: 2 } }}>
      <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
        {t('total_airborne_bacteria_info')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IoEyeSharp style={{ color: 'rgba(255, 165, 0, 1)', fontSize: 34 }} />
        <Typography variant="h5">
          {t('prediction_value')}
          <Box component="span" sx={{ display: 'block', }}>
            {t('prediction')}
          </Box>
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between', borderColor: 'rgba(0, 0, 255, 1)', }}>
        <Box sx={{ fontWeight: 'bold' }}>
          <Typography variant="h2" align="center" sx={{ fontWeight: 'bold' }}>
            {currentReportInfo?.sensorHumi}
          </Typography>
          <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
            CFU
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" align="center">
            IAQ
          </Typography>
          <Box>
            <DisplayColorFulTable />
          </Box>
          <Typography align="center" sx={{ fontSize: '1.25rem', color: 'rgba(255, 0, 0, 1)', mt: 2 }}>
            {t('very_unhealthy')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          ({t('previous_value')})
        </Typography>
        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold' }}>
          {currentReportInfo?.sensorTemp}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', color: 'rgba(0, 0, 255, 1)' }}>
          CFU
        </Typography>
      </Box>
    </Box>
  );
}

export default DisplayBacteriaData;
