import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentFacility: { facilityName: 'Total', facilityId: 'total' }
};

const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    setFacility: (state, action) => {
      state.currentFacility = action.payload;
    },
  },
});

export const {
  setFacility
} = facilitySlice.actions;
export default facilitySlice.reducer;