import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SelectMenu from '../SelectMenu';
import { Button, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import LanguagesMenu from '../LanguagesMenu';
import { HiMiniBars3BottomRight, HiMiniBars4 } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';

function Header({ onToggleSidebar, onSidebarVisible }) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseSidebar = () => {
    if (window.innerWidth <= 1200 && onSidebarVisible) {
      onToggleSidebar(false);
    }
  };

  const handleCloseAvatar = () => {
    setAnchorEl(null);
  };
  const handleAvatarMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box onClick={handleCloseSidebar}>
      <AppBar position="static" sx={{ bgcolor: '#ffffff', borderBottom: '1px solid #e0e0e0' }}>
        <Toolbar>
          <Button
            onClick={() => onToggleSidebar(!onSidebarVisible)}
            sx={{
              borderRadius: 1, minWidth: 'max-content', color: '#000000', '&:hover': {
                backgroundColor: '#e0e0e0',
              }
            }}
          >
            {onSidebarVisible ? <HiMiniBars3BottomRight size={24} /> : <HiMiniBars4 size={24} />}
          </Button>

          <SelectMenu />

          {/* Needed for pushing elements to right side */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

          <LanguagesMenu />

          {/* Account page */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleAvatarMenu}
            >
              <AccountCircle sx={{ width: '2.2rem', height: '2.2rem' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseAvatar}
            >
              <MenuItem onClick={handleCloseAvatar}>{t('my_account')}</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
