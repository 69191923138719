import PropTypes from 'prop-types';

// material-ui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MainCard from './MainCard';
import { fontStyle } from '../pages/dashboard/utils';
import { t } from 'i18next';


export default function OverviewBox({ data }) {
  return (
    <MainCard contentSX={{ p: 2.25 }}>
      <Typography sx={{
        ...fontStyle,
      }}
        variant="h6" color="text.secondary">
        {t(data.title)}
      </Typography>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h4" color="inherit" sx={{
            ...fontStyle
          }}
          >
            {data.value}
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
}

OverviewBox.propTypes = {
  title: PropTypes.string,
  count: PropTypes.string
};
