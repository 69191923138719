import { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const xLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

// Dummy data
const pData = [
  [110, 100, 110, 120, 100, 25],
  [0.2, 0.5, 1, 6.2, 5.8, 1.8],
  [40, 39, 48, 40, 50, 10],
  [110, 100, 110, 120, 100, 25],
  [0.2, 0.5, 1, 6.2, 5.8, 1.8],
  [40, 39, 48, 40, 50, 10],
  [0.2, 0.5, 1, 6.2, 5.8, 1.8],
  [40, 39, 48, 40, 50, 10],
];

function BarChartContainer() {
  const { t } = useTranslation()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '100%' }}>
      <Typography variant="h6" align="center" sx={{ pb: 2, fontWeight: 500 }}>
        {t('monthly_avg_pollution_level')}
      </Typography>
      {pData.map((data, dataId) => (
        <BarChart
          key={dataId}
          margin={{ top: 5 }}
          slotProps={{
            // Custom loading message
            loadingOverlay: { message: 'Data should be available soon.' },
            // Custom message for empty chart
            noDataOverlay: { message: 'Select some data to display.' },
          }}
          width={windowWidth <= 1200 ? windowWidth - 100 : windowWidth >= 1850 ? 410 : windowWidth >= 1600 ? 340 : 300}
          height={100}
          series={[
            {
              data: data,
              yAxisKey: 'customYAxisId',
            },
          ]}
          xAxis={[{ data: xLabels, scaleType: 'band' }]}
          yAxis={[{ id: 'customYAxisId' }]}
        />
      ))}
    </Box>
  );
}

export default BarChartContainer;
