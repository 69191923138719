import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function Spinner() {
  return (
    <Box
      role="status"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          width: '64px',
          height: '64px',
          color: 'rgba(25, 118, 210, 1)', // blue-600 color
          '& .MuiCircularProgress-circle': {
            stroke: 'currentColor',
          },
        }}
      />
    </Box>
  );
}

export default Spinner;
