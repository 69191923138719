import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import DisplayColorFulTable from './DisplayColorFulTable';
import { useTranslation } from 'react-i18next';

function DisplayBacteriaDegree({ currentReportInfo }) {
  const { t } = useTranslation()

  // Data Modification for DisplayBacteriaDegree components
  const sensorDegreeData = [
    { degree: currentReportInfo?.sensorPm10, description: 'fine_dust', type: 'POM10' },
    { degree: currentReportInfo?.sensorCo, description: 'carbon_monoxide', type: 'CO' },
    { degree: currentReportInfo?.sensorTvoc, description: 'total_volatile_organic_compound', type: 'TVOC' },
    { degree: currentReportInfo?.sensorPm25, description: 'ultrafine_dust', type: 'PM2.5' },
    { degree: currentReportInfo?.sensorCo2, description: 'carbon_dioxide', type: 'CO2' },
    { degree: currentReportInfo?.sensorCh2o, description: 'formaldehyde', type: 'HCHO' },
  ];

  return (
    <Box
      sx={{ height: '100%', display: { xs: 'block', lg: 'grid' }, gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' }, columnGap: 2, rowGap: 7 }}
    >
      {sensorDegreeData.map((sensor, id) => (
        <Box
          key={id}
          sx={{ display: 'flex', justifyContent: 'space-between', pb: { xs: 2, lg: 0 } }}
        >
          <Box sx={{ width: '6.5rem' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#34d399' }}>
              {sensor.type}
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>{t(sensor.description)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ width: 'max-content' }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {sensor.degree}
              </Typography>
              <Typography sx={{ fontWeight: 'bold', mt: -1 }}>mg/m3</Typography>
            </Box>
            <DisplayColorFulTable />
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default DisplayBacteriaDegree;
