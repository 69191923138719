import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { keyframes } from '@mui/system';

export const routes = [
  {
    to: '/',
    icon: <DashboardIcon />,
    text: 'dashboard',
  },
  {
    to: '/monitoring',
    icon: <DeviceHubIcon />,
    text: 'monitoring',
  },
  {
    to: '/trends',
    icon: <BarChartIcon />,
    text: 'trend',
  },
  {
    to: '/report',
    icon: <SummarizeIcon />,
    text: 'report',
  },
  // {
  //   to: '/question',
  //   icon: <QuestionAnswerIcon />,
  //   text: 'questioning',
  // },
];

// Define the keyframes
export const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
export const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;
