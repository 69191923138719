import { Grid, Typography } from '@mui/material'
import React from 'react'
import { fontStyle } from '../utils';
import { useTranslation } from 'react-i18next';

function AlarmsDisplay({ alarm }) {
  const { t } = useTranslation();
  return (
    <Grid sx={{ padding: '15px' }} item>
      <Typography sx={{ ...fontStyle, fontWeight: 700 }}>{t('alarm') + ' #' + (alarm.alarmId + 1)}</Typography>
      <Typography sx={{ ...fontStyle, marginTop: '10px', color: 'red', fontWeight: 200, fontSize: '0.9rem' }}>
        {t(`alarm_${alarm.alarm}`)}
      </Typography>
    </Grid>
  )
}
export default AlarmsDisplay
