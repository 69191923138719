import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../Logo.png';
import { useState } from 'react';
import { signInFailure, signInStart, signInSuccess } from '../../redux/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../redux/alert/alertSlice';
import { openPopup } from '../../redux/popup/popupSlice';
import Spinner from '../../components/Spinner';

const defaultTheme = createTheme();

function AuthRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    remember: '',
    phoneNumber: '',
    companyName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(signInStart());
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          companyName: formData.companyName,
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
        }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(signInFailure(data.message || 'Registration failed'));
        dispatch(
          setAlert({
            type: 'error',
            message: data.message || 'Something went wrong, Please try again with correct values!',
          })
        );
        return;
      }

      dispatch(
        openPopup({
          title: '성공적으로 등록되었습니다!',
          message:
            '사용자 등록 신청이 성공적으로 접수되었습니다. 확인 후 계정에 로그인할 수 있도록 이메일을 보내드립니다!',
        })
      );
      navigate('/login');
      dispatch(signInSuccess());
    } catch (err) {
      dispatch(signInFailure(err.message));
      dispatch(
        setAlert({
          type: 'error',
          message: err.message || 'Something went wrong, Please try again with correct values!',
        })
      );
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          {loading && <Spinner />}
          <Box
            component='img'
            sx={{
              height: 64,
            }}
            alt='Logo'
            src={Logo}
          />
          <Typography variant='h1' sx={{ my: 1, fontWeight: 'bold', fontSize: '1.1rem' }}>
            공기질 통합 모니터링 플랫폼
          </Typography>

          <Typography level='body-sm'>세션 시작을 위해 가입 해주세요</Typography>
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
              required
              fullWidth
              id='name'
              label='이름'
              type='text'
              name='name'
              value={formData?.name}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
              required
              fullWidth
              id='email'
              type='email'
              label='이메일 주소'
              name='email'
              value={formData?.email}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
              required
              fullWidth
              id='phoneNumber'
              label='전화 번호'
              type='text'
              name='phoneNumber'
              value={formData?.phoneNumber}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
              required
              fullWidth
              id='companyName'
              label='회사 이름'
              type='text'
              name='companyName'
              value={formData?.companyName}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
              required
              fullWidth
              name='password'
              label='비밀번호'
              type='password'
              id='password'
              value={formData?.password}
              onChange={handleChange}
            />
            <TextField
              sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
              required
              fullWidth
              name='passwordConfirm'
              label='비밀번호 확인'
              type='password'
              id='passwordConfirm'
              value={formData?.passwordConfirm}
              onChange={handleChange}
            />

            <Box>
              <Link href='/login' variant='body2'>
                {'이미 계정이 있나요? 로그인'}
              </Link>
            </Box>

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 1.5, borderRadius: '20px' }}>
              가입하기
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AuthRegister;
