import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../Logo.png';
import { useState } from 'react';
import { openPopup } from '../../redux/popup/popupSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function AuthResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  });

  // Function to handle onChange for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newPassword: formData.password,
          newPasswordConfirm: formData.passwordConfirm,
          token: token,
        }),
      });

      const data = await res.json();
      if (!res.ok) {
        dispatch(openPopup({ title: 'Reset Your Password!', message: data.message }));
        setLoading(false); // Stop loading
        return;
      }
      dispatch(setAlert({ type: 'success', message: data.message }));
      navigate('/login');
    } catch (err) {
      dispatch(setAlert({ type: 'error', message: err.message }));
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          {loading && <Spinner />}
          <Box
            component='img'
            sx={{
              height: 64,
            }}
            alt='Logo'
            src={Logo}
          />
          <Typography variant='h1' sx={{ mt: 1, mb: 4, fontWeight: 'bold', fontSize: '1.1rem' }}>
            공기질 통합 모니터링 플랫폼
          </Typography>
          <Typography level='body-sm'>새로운 비밀번호와 비밀번호 확인을 입력해 주세요.</Typography>
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='비밀번호'
              type='password'
              id='password'
              onChange={handleChange}
              autoFocus
            />

            <TextField
              margin='normal'
              required
              fullWidth
              name='passwordConfirm'
              label='비밀번호'
              type='password'
              id='passwordConfirm'
              onChange={handleChange}
            />

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2, borderRadius: '20px' }}>
              계속하기
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AuthResetPassword;
