import React, { forwardRef } from 'react';
import { FormControl, FormControlLabel, TextField } from '@mui/material';

const CustomTextField = forwardRef(({ label, sx, errMg, required, typeValue, onChange, textFieldStyle, align, ...rest }, ref) => {
  if (!label)
    return (
      <FormControl sx={{ ...sx }}>
        <TextField
          size="small"
          sx={{
            '.MuiInputBase-root': {
              bgcolor: 'common.white'
            },
            ...textFieldStyle
          }}
          onChange={(e) => {
            if (typeValue === 'onlyNumber') {
              const value = e.target.value.replace(/\D/g, '');
              e.target.value = value;
              onChange(e);
            } else {
              onChange(e);
            }
          }}
          error={errMg}
          inputRef={ref}
          {...rest}
        />
        {errMg && (
          <span
            style={{
              color: 'red',
              fontSize: 12
            }}
          >
            * {errMg}
          </span>
        )}
      </FormControl>
    );
  return (
    <FormControl sx={{ flexDirection: 'row', gap: 1, ...sx }} error={errMg}>
      <FormControlLabel
        label={
          <span>
            {label} <span style={{ color: 'red' }}>{required && '*'}</span>
          </span>
        }
        labelPlacement={align ? align : 'start'}
        sx={{
          '.MuiTypography-root': {
            color: '#080808',
            fontWeight: 'fontWeightBold',
            fontSize: 13,
            whiteSpace: 'nowrap'
          }
        }}
        control={
          <TextField
            size="small"
            sx={{
              '.MuiInputBase-root': { bgcolor: 'common.white' },
              m: 1
            }}
            onChange={(e) => {
              if (typeValue === 'onlyNumber') {
                const value = e.target.value.replace(/\D/g, '');
                e.target.value = value;
                onChange(e);
              } else {
                onChange(e);
              }
            }}
            inputRef={ref}
            {...rest}
          />
        }
      />
      {errMg && (
        <span
          style={{
            color: 'red',
            fontSize: 12
          }}
        >
          * {errMg}
        </span>
      )}
    </FormControl>
  );
});

export default CustomTextField;
