import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, TextField, Typography } from '@mui/material';
import parse from 'html-react-parser';

import {
  deleteQuestionFailure,
  deleteQuestionStart,
  deleteQuestionSuccess,
  updateQuestionFailure,
  updateQuestionStart,
  updateQuestionSuccess,
} from '../../redux/question/questionSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { getAuthToken } from '../../utils/jwtUtils';
import { openPopup } from '../../redux/popup/popupSlice';
import Reply from './Reply';
import { useTranslation } from 'react-i18next';

const ShowQAWPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentQuestion } = useSelector((state) => state.question);
  const [newQuestion, setNewQuestion] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const token = getAuthToken();

  // Parsing data to get text
  const questionText = typeof currentQuestion?.question === 'string' ? currentQuestion.question : '';
  const dbQuestion = parse(questionText);
  const initialQuestionContent = dbQuestion.props?.children?.props ? dbQuestion.props?.children?.props?.children : dbQuestion.props?.children;

  // Fake user type
  let userType = "ADMIN"; // ADMIN & USER

  const handleChange = (e) => {
    setNewQuestion({ question: newQuestion, [e.target.name]: e.target.value });
    if (initialQuestionContent) {
      setIsChanged(e.target.value !== initialQuestionContent);
      return
    }
    setIsChanged(e.target.value !== dbQuestion);

  };

  const handleDelete = async () => {
    try {
      dispatch(deleteQuestionStart());
      const questionId = currentQuestion.id;
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions/${questionId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        dispatch(deleteQuestionFailure("Something went wrong, Could not delete the question!"));
        dispatch(openPopup({ title: "Do you want to delete the question?", message: "Something went wrong, Could not delete the question!" }));
        return;
      }

      dispatch(deleteQuestionSuccess());
      dispatch(setAlert({ type: "success", message: "Question deleted successfully!" }));
      navigate('/questionList');
    } catch (err) {
      dispatch(deleteQuestionFailure(err.message));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(updateQuestionStart());
      const questionId = currentQuestion.id;
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/questions/${questionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newQuestion),
      });
      const data = await res.json();

      if (!res.ok) {
        dispatch(updateQuestionFailure(data.message));
        dispatch(openPopup({ title: "Do you want to update your question?", message: data.message || "Something went wrong, Could not update the question!" }));
        return;
      }

      dispatch(setAlert({ type: "success", message: "Updated successfully!" }));
      dispatch(updateQuestionSuccess(data));
      setIsChanged(false); // Reset the state after successful update
    } catch (err) {
      dispatch(updateQuestionFailure(err.message));
    }
  };

  return (
    <Box sx={{ width: { xs: '100%', md: '90%' }, mt: 4, mx: 'auto', bgcolor: 'rgba(255, 255, 255, 1)', borderRadius: 1, boxShadow: 3, p: 2 }}>
      <Typography variant="h5" fontWeight="bold" sx={{ py: 2 }}>
        {t('question')} <Typography component="span" fontWeight="normal">{t('view')}</Typography>
      </Typography>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 2 }}>
        <Typography variant="body1">{t('content')}</Typography>
        <Typography variant="h6">{currentQuestion?.title}</Typography>
      </Box>

      {/* Text Area */}
      <form onSubmit={handleSubmit}>
        <TextField
          required
          id="question"
          name="question"
          multiline
          rows={12}
          fullWidth
          defaultValue={typeof dbQuestion === "object" ? initialQuestionContent : currentQuestion?.question}
          onChange={handleChange}
          variant="outlined"
          sx={{ mt: 2 }}
        />

        {/* Footer Reply tab && btns */}
        {userType === "ADMIN" && <Reply />}
        {userType === "USER" && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            <Button variant="contained" color="secondary" onClick={handleDelete}>
              {t('delete')}
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={!isChanged}>
              {t('update')}
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default ShowQAWPage;
