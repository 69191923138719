

export const dateActions = {
  TODAY: '오늘',
  THREE_DAYS: '3일',
  ONE_MONTH: '1개월',
  THREE_MONTHS: '3개월',
  SIX_MONTHS: '6개월',
  ONE_YEAR: '1년',
  ALL: '올해'
};

export const sensorActions = {
  TAB: 'TAB',
  PM10: 'PM10',
  PM2_5: 'PM25',
  CO: 'CO',
  CO2: 'CO2',
  TVOC: 'TVOC',
  CH2O: 'CH2O',
  NO2: 'NO2',
};