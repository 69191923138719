import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentQuestion: null,
  error: null,
  loading: false,
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    postQuestionStart: (state) => {
      state.loading = true;
    },
    postQuestionSuccess: (state, action) => {
      state.currentQuestion = action.payload;
      state.loading = false;
      state.error = null;
    },
    postQuestionFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateQuestionStart: (state) => {
      state.loading = true;
    },
    updateQuestionSuccess: (state, action) => {
      state.currentQuestion = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateQuestionFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteQuestionStart: (state) => {
      state.loading = true;
    },
    deleteQuestionSuccess: (state) => {
      state.currentQuestion = null;
      state.loading = false;
      state.error = null;
    },
    deleteQuestionFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  postQuestionStart,
  postQuestionSuccess,
  postQuestionFailure,
  updateQuestionStart,
  updateQuestionSuccess,
  updateQuestionFailure,
  deleteQuestionStart,
  deleteQuestionSuccess,
  deleteQuestionFailure,

} = questionSlice.actions;
export default questionSlice.reducer;