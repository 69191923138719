import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import moment from 'moment';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import { getAuthToken } from '../../../utils/jwtUtils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Initial chart options
const initialOptions = {
  chart: {
    height: 340,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  },
  xaxis: {
    categories: [],
    labels: {
      show: false,
      style: {
        colors: [],
        fontSize: '12px'
      }
    }
  },
  yaxis: {
    labels: {
      style: {
        colors: []
      }
    }
  }
};

// ==============================|| BACTERIAL LEVEL CHART ||============================== //

export default function BacterialLevelChart({ date, slot }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const token = getAuthToken();
  const { currentFacility } = useSelector(state => state.facility);

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(initialOptions);
  const [series, setSeries] = useState([]);

  const fetchCulturingHistoryDayAQI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/culturingDayHistoryAQI`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: date.startDate,
          endDate: date.endDate,
          facilityId: currentFacility.facilityId,
        }),
      });

      const data = await response.json(); // Parse JSON here

      // Check for errors or no data after parsing JSON
      if (!response.ok || (data.message && data.message === 'No culturing AQI found for this month!')) {
        // Handle no data case by clearing the chart
        setSeries([]);
        setOptions((prevState) => ({
          ...prevState,
          xaxis: {
            ...prevState.xaxis,
            categories: [],
          },
        }));
        return;
      }

      // Format dates using moment
      const aqiData = data.map((entry) => entry.aqi);
      const culturingDates = data.map((entry) => moment(entry.culturingStartDttm, 'YYYYMMDDHHmmss').format('YYYY/MM/DD'));

      // Update the series with AQI data
      setSeries([
        {
          name: `AQI ${t('value')}`,
          data: aqiData
        }
      ]);

      // Update x-axis categories dynamically
      setOptions((prevState) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          categories: culturingDates,
          labels: {
            style: {
              colors: new Array(culturingDates.length).fill(secondary),
              fontSize: '12px' // Customize font size if needed
            }
          },
          axisBorder: {
            show: false, // Hide axis border
          },
          axisTicks: {
            show: false, // Hide axis ticks
          },
        }
      }));
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchCulturingHistoryDayAQI();
  }, [date, currentFacility, t]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        ...prevState.xaxis,
        labels: {
          show: false,
          style: {
            colors: new Array(prevState.xaxis.categories.length).fill(secondary),
            fontSize: '12px' // Customize font size if needed
          }
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      }
    }));
  }, [primary, secondary, line, theme, slot]);

  return <ReactApexChart options={options} series={series} type="area" height={380} />;
}

BacterialLevelChart.propTypes = {
  slot: PropTypes.string,
  date: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired
  }).isRequired
};
