import { MRT_TableBodyCellValue, MRT_TablePagination, flexRender, useMaterialReactTable } from 'material-react-table';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postQuestionSuccess } from '../redux/question/questionSlice';

import React from 'react';
import SubTable from './SubTable';
import { useTranslation } from 'react-i18next';

const MyTable = ({ columns, data }) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTableRowClick = async (row) => {
    const rowData = row.original;
    if (rowData) {
      dispatch(postQuestionSuccess(rowData));
      navigate('/showQuestion');
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: false,
    },
    // Customize the MRT components
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10],
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
  });

  return (
    <Stack sx={{ m: '1rem 0' }}>
      {/* Using Vanilla Material-UI Table components here */}
      <TableContainer>
        <Table>
          {/* Use your own markup, customize however you want using the power of TanStack Table */}
          <TableHead>
            {table.getHeaderGroups().map((headerGroup, id) => (
              <TableRow
                key={id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                {headerGroup.headers.map((header, id) => (
                  <TableCell align="center" variant="head" key={id} sx={{ width: header.column.columnDef.size }}>
                    {header.isPlaceholder
                      ? null
                      : t(flexRender(header.column.columnDef.header, header.getContext()))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((parentRow, id) => (
              <React.Fragment key={id}>
                {/* Main row */}
                <TableRow
                  key={id}
                  selected={parentRow.getIsSelected()}
                  onClick={() => handleTableRowClick(parentRow)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  {parentRow.getVisibleCells().map((cell) => (
                    <TableCell align="center" variant="body" key={cell.id}>
                      {/* Use MRT's cell renderer that provides better logic than flexRender */}
                      <MRT_TableBodyCellValue cell={cell} table={table} />
                    </TableCell>
                  ))}
                </TableRow>
                {/* Sub-row */}
                {
                  parentRow.original.answers.map((answer, id) => <SubTable key={id} columns={columns} answer={answer} />)
                }
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {/* Use MRT components along side your own markup. They just need the `table` instance passed as a prop to work! */}
        <MRT_TablePagination table={table} />
      </Box>
    </Stack>
  );
};

export default MyTable;
