import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Card, Typography, Stack, Box, ImageListItem } from '@mui/material';
import MainCard from '../../../components/MainCard';
import { fontStyle } from '../../dashboard/utils';
import { useTranslation } from 'react-i18next';
import '../../dashboard/swiper/swiper.css';

export default function ImagesCarousel({ data }) {
  const { t } = useTranslation();
  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    3000: {
      slidesPerView: 4,
    },
  };

  return (
    <Swiper
      spaceBetween={10}
      breakpoints={breakpoints}
      loop={true}
      navigation={true}
      pagination={{ clickable: true }}
      modules={[Navigation]}
    >
      {data.map((slide, i) => (
        <SwiperSlide key={i}>
          <Card sx={{ width: '100%' }}>
            <MainCard sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} content={false}>
              <Box sx={{ display: 'flex', alignItems: 'center', my: 1, gap: 1 }}>
                <Box>
                  <Typography sx={{ ...fontStyle, fontWeight: 'bold' }} variant='h5'>
                    {slide.hour} {t('hour_ago')}
                  </Typography>
                  <Typography sx={{ ...fontStyle }} variant='h5'>
                    {t('bacteria_level')}
                  </Typography>
                </Box>
                <Typography sx={{ ...fontStyle, fontSize: '1.4rem' }} variant='h5'>
                  10
                </Typography>
              </Box>
              <ImageListItem sx={{ overflow: 'hidden' }}>
                <img
                  src={slide.src}
                  alt={'Colony Image'}
                  loading="lazy"
                  style={{ width: '100%', height: '100%' }}
                />
              </ImageListItem>
            </MainCard>
          </Card>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
