import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SensorChart from '../components/SensorChart';
import { Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';
import { Navigation } from 'swiper/modules';
import MainCard from '../../../components/MainCard';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getAuthToken } from '../../../utils/jwtUtils';
import { sensorElements } from '../utils';
import { useSelector } from 'react-redux';

export default function DashboardCarousel({ date }) {
  const { t } = useTranslation();
  const token = getAuthToken();
  const { currentFacility } = useSelector((state) => state.facility);
  const [graphData, setGraphData] = useState({});
  const [aqiData, setAqiData] = useState(null); // Initialize as null
  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1800: {
      slidesPerView: 5,
    },
    3000: {
      slidesPerView: 6,
    },
  };

  const getGraphData = async () => {
    const startDate = moment(date.startDate, 'YYYYMMDDHHmmss').format('YYYYMMDD');
    const endDate = moment(date.endDate, 'YYYYMMDDHHmmss').format('YYYYMMDD');

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/facilityStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ startDate, endDate, facilityId: currentFacility.facilityId }),
      });

      const data = await res.json();

      if (!res.ok || (data.message && data.message === 'No data found for selected dates!')) {
        setGraphData({});
        console.error('Error fetching data:', data.message || 'No data found for selected dates!');
        return;
      }

      const labels = [];
      const datasets = {};

      sensorElements.forEach((sensor) => {
        datasets[sensor.sensor] = {
          min: { label: t('min'), data: [], borderColor: 'rgb(75, 192, 192)', backgroundColor: 'rgba(75, 192, 192)', fill: false },
          max: { label: t('max'), data: [], borderColor: 'rgb(255, 99, 132)', backgroundColor: 'rgba(255, 99, 132)', fill: false },
          avg: { label: t('avg'), data: [], borderColor: 'rgb(54, 162, 235)', backgroundColor: 'rgba(54, 162, 235)', fill: false },
        };
      });

      data.forEach((entry) => {
        const dateString = `${entry.createDttm[0]}-${entry.createDttm[1]}-${entry.createDttm[2]}`;
        if (!labels.includes(dateString)) {
          labels.push(dateString);
        }

        sensorElements.forEach((sensor) => {
          datasets[sensor.sensor].min.data.push(entry[`sensor${sensor.sensor}Min`]);
          datasets[sensor.sensor].max.data.push(entry[`sensor${sensor.sensor}Max`]);
          datasets[sensor.sensor].avg.data.push(entry[`sensor${sensor.sensor}Avg`]);
        });
      });

      labels.sort();

      const formattedData = {};
      Object.keys(datasets).forEach((sensor) => {
        formattedData[sensor] = { labels, datasets: Object.values(datasets[sensor]) };
      });

      setGraphData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setGraphData({});
    }
  };

  const fetchCulturingHistoryDayAQI = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/culturingDayHistoryAQI`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: date.startDate,
          endDate: date.endDate,
          facilityId: currentFacility.facilityId,
        }),
      });

      const data = await response.json();

      if (!response.ok || (data.message && data.message === 'No culturing AQI found for this month!')) {
        setAqiData(null); // Set to null when there's no data
        return;
      }

      const aqiValues = data.map((entry) => entry.aqi);
      const culturingDates = data.map((entry) => moment(entry.culturingStartDttm, 'YYYYMMDDHHmmss').format('MMMM D, YYYY'));

      setAqiData({ labels: culturingDates, values: aqiValues });
    } catch (error) {
      console.error('Failed to fetch AQI data:', error);
      setAqiData(null); // Set to null on error
    }
  };

  useEffect(() => {
    getGraphData();
    fetchCulturingHistoryDayAQI(); // Fetch AQI data
  }, [t, date, currentFacility]);

  return (
    <Swiper
      spaceBetween={10}
      breakpoints={breakpoints}
      loop={true}
      navigation={true}
      pagination={true}
      modules={[Navigation]}
    >
      {sensorElements.map((slide, index) => (
        <SwiperSlide key={index}>
          <MainCard>
            <Typography sx={{ textAlign: 'center' }} variant='h6'>
              {slide.sensor}
            </Typography>
            {slide.sensor === 'TAB' ? (
              aqiData && aqiData.labels.length > 0 ? (
                <SensorChart
                  data={{
                    labels: aqiData.labels,
                    datasets: [
                      {
                        label: `${t('avg')}`,
                        data: aqiData.values,
                        borderColor: 'rgba(54, 162, 235)',
                        backgroundColor: 'rgba(54, 162, 235)',
                        fill: false,
                      },
                    ],
                  }}
                />
              ) : (
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {t('No data available')}
                </Typography>
              )
            ) : graphData[slide.sensor] ? (
              <SensorChart data={graphData[slide.sensor]} />
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('No data available')}
              </Typography>
            )}
          </MainCard>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
